table {
    $background: #4f80bd;

    thead,
    tfoot {
        background-color: $background;
        color: ghostwhite;
    }

    tbody {
        tr:nth-child(even) {
            td {
                background-color: lighten($background, 37.5%);

                &.table-button.active,
                &.table-button:hover {
                    background-color: lighten($background, 20%);
                }
            }

            &:hover {
                td {
                    background-color: lighten($background, 25%);

                    &.hoverable {
                        cursor: pointer;
                    }
                }
            }
        }

        tr:nth-child(odd) {
            td {
                background-color: lighten($background, 42.5%);

                &.table-button.active,
                &.table-button:hover {
                    background-color: lighten($background, 20%);
                }
            }

            &:hover {
                td {
                    background-color: lighten($background, 30%);

                    &.hoverable {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
