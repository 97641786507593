@import "./colors";

a:hover {
    text-decoration: none;
}

.blue {
    color: $blue;
}

.small {
    font-size: small;
}

footer .eula {
    margin-bottom: 10px;
    text-align: center;
}

footer .visible-print-block,
footer.visible-print-block .eula {
    text-align: initial;
}

.graph {
    height: 15rem;
    width: 100%;
    background-color: whitesmoke;
    border: thin solid darkgrey;
}

a:hover {
    button {
        text-decoration: none;
    }
}

:disabled {
    cursor: not-allowed;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.disabled.dropdown.nav-item {
    cursor: not-allowed;

    .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3) !important;
    }
}

.text-underline {
    text-decoration: underline;
}
