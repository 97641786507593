@import "./colors";

.navbar {
    background-color: $background-light;
    border: 1px solid $background-dark;
    padding: 0 10px !important;
    min-height: 3rem;

    img {
        padding: .15rem 0;
        height: 3rem;
    }

    .navbar-brand {
        font-size: 26px;
        color: rgb(79, 128, 189);
    }

    a:focus {
        background-color: $background-dark;
        color: $gray;
    }

    .nav-link.active {
        background-color: $background-dark;
        color: $gray;
    }

    .nav-link {
        height: 58px;
        padding-top: 1rem;
        color: #212529 !important;

        &:hover, &.active, &:focus {
            background-color: $background-dark;
            color: $gray;
        }
    }

    .tagline {
        transform: translateY(-2px);
        font-size: 0.75rem;
        font-style: italic;
        color: rgb(0, 0, 0);
    }
}



