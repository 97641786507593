#species {
    input.is_invalid {
        border: 1px solid #dc3545;
    }

    .species-selector + div {
        width: inherit;
        position: absolute;
        background-color: white;
        z-index: 1;
        max-height: 15rem;
        border-bottom: 1px solid darkgray;

        &.open {
            border: 1px solid darkgray;
            border-top: none;
            overflow-y: scroll;
        }

        span {
            width: 26.5rem;
            background-color: white;
            padding: 5px 15px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            display: block;

            &:hover {
                background-color: #007bff;
                color: white;
            }
        }
    }
}